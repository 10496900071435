


























































import { ref, computed } from '@vue/composition-api';
import { useUserState } from '@/store';

export default {
  name: 'Badges',
  setup() {
    const { user }: any = useUserState(['user']);
    const badges = computed(() => {
      return user?.value?.badges?.filter(badge => badge?.type === 'reward') || [];
    });
    const credentials = computed(() => {
      return user?.value?.badges?.filter(badge => badge?.type === 'credential') || [];
    });
    return {
      user,
      badges,
      credentials
    };
  }
};
